define("admin/adapters/site-inspection", ["exports", "admin/adapters/application", "admin/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    namespace: `${_environment.default.api.namespace}/admin`,
    duplicate: (headers, workOrder) => {
      const url = `${_environment.default.api.host}/${_environment.default.api.namespace}/admin/site_inspections/${workOrder.get('id')}/duplicate`;
      const xhrOptions = {
        url,
        method: 'POST',
        data: {},
        headers
      };
      return Ember.$.ajax(xhrOptions);
    },
    bulkUpdate: (headers, data) => {
      return Ember.$.ajax({
        url: `${_environment.default.api.host}/v1/admin/site_inspections/bulk_status_update`,
        method: 'POST',
        dataType: 'json',
        data,
        headers
      });
    }
  });
});