define("admin/routes/site-inspections/index", ["exports", "ember-infinity/mixins/route", "admin/config/environment"], function (_exports, _route, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_route.default, {
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    queryParams: {
      q: {
        refreshModel: true
      },
      attr: {
        refreshModel: true
      },
      sort_attribute: {
        refreshModel: true
      },
      sort_order: {
        refreshModel: true
      },
      statuses: {
        refreshModel: true
      },
      legal_entities: {
        refreshModel: true
      },
      target_start_date_start: {
        refreshModel: true
      },
      target_start_date_end: {
        refreshModel: true
      },
      target_end_date_start: {
        refreshModel: true
      },
      target_end_date_end: {
        refreshModel: true
      },
      committed_start_date_start: {
        refreshModel: true
      },
      committed_start_date_end: {
        refreshModel: true
      },
      pilot_ids: {
        refreshModel: true
      },
      client_ids: {
        refreshModel: true
      },
      organization_ids: {
        refreshModel: true
      },
      bulk_update_enabled: {
        refreshModel: true
      },
      'country_codes': {
        refreshModel: true,
        type: 'array'
      }
    },
    serializeQueryParam(value, urlKey, defaultValueType) {
      return defaultValueType === 'array' ? value : this._super(value, urlKey, defaultValueType);
    },
    deserializeQueryParam(value, urlKey, defaultValueType) {
      return defaultValueType === 'array' ? value : this._super(value, urlKey, defaultValueType);
    },
    model(params) {
      params['perPage'] = 25;
      params['startingPage'] = 1;
      return Ember.RSVP.hash({
        workOrders: this.infinityModel('site-inspection', params),
        legalEntities: this.store.findAll('legal-entity'),
        currentAdmin: this.store.findRecord('admin', this.get('session.data.authenticated.id')),
        countries: this.store.findAll('country'),
        countryCodesParams: params.country_codes
      });
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.set('countries', model.countries);
      controller.set('country_codes', model.countryCodesParams);
      if (controller.get('qInput.length') === 0) controller.set('qInput', controller.get('q'));
      Ember.$.ajax({
        url: `${_environment.default.api.host}/v1/admin/site_inspections/statuses`,
        type: 'GET',
        dataType: 'json',
        headers: this.get('sessionAccount.headers')
      }).then(response => {
        controller.set('inspectionStatuses', response['statuses'].map(status => {
          return {
            value: status,
            name: status.split('_').map(word => word.capitalize()).join(' ')
          };
        }));
        controller.set('inspectionAvailableStatusTransitions', response['transitions'].map(transition => {
          return {
            from: Object.keys(transition)[0],
            to: Object.values(transition)[0]
          };
        }));
      }, response => {
        console.error(response.responseJSON.errors.map(e => e.details));
      });
      if (model.currentAdmin && !model.currentAdmin.canSeeBulkUpdater) {
        return this.transitionTo({
          queryParams: {
            bulk_update_enabled: null
          }
        });
      }
    }
  });
});