define("admin/controllers/missions/index", ["exports", "admin/config/environment", "admin/data/searches/mission_search_attributes", "admin/data/verticals_groups"], function (_exports, _environment, _mission_search_attributes, _verticals_groups) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-observers */
  /* eslint-enable ember/no-observers */
  var _default = _exports.default = Ember.Controller.extend({
    NEW_LINE_CHAR: "\n",
    QUERY_LENGTH_LIMIT: 8000,
    router: Ember.inject.service(),
    infinity: Ember.inject.service(),
    queryParams: Ember.A(['status', 'vertical', 'attr', 'q', 'sort_attribute', 'sort_order', 'lat', 'lon', 'distance', 'assets_late', 'include_client_ids', 'exclude_client_ids', 'reshoot', 'on_hold', 'start_daterange', 'end_daterange', 'bulk_update_enabled', 'country_codes']),
    sortProperties: Ember.A(['updated:desc']),
    attr: 'number',
    q: '',
    qInput: '',
    status: null,
    lat: null,
    lon: null,
    distance: null,
    assets_late: false,
    on_hold: false,
    reshoot: false,
    include_client_ids: null,
    vertical: null,
    exclude_client_ids: null,
    sortAttribute: 'updated',
    sortOrder: 'desc',
    showMap: false,
    // Bulk edit attributes
    bulk_update_enabled: false,
    showActionsDropdown: false,
    selectedMissionsNumbers: Ember.A([]),
    bulkStatusUpdateModalVisible: false,
    bulkAddToExistingCaptureGroupModalVisible: false,
    bulkAddToNewCaptureGroupModalVisible: false,
    bulkAddToCaptureGroupWarningModalVisible: false,
    bulkStatusUpdateErrorsModalVisible: false,
    bulkStatusUpdateErrors: null,
    bulkUpdating: false,
    bulkAddToCGCallback: null,
    busy: false,
    downloaded: '',
    multiLineSearchOpened: false,
    notMatchedLinesVisibile: false,
    countries: null,
    country_codes: Ember.A([]),
    searchAttributesOptions: _mission_search_attributes.default,
    verticals: _verticals_groups.default,
    /* eslint-disable ember/no-observers */
    missionsPayloadChangeObserver: Ember.observer('status', 'q', 'sort_attribute', 'sort_order', 'lat', 'lon', 'distance', 'assets_late', 'include_client_ids', 'exclude_client_ids', 'reshoot', 'on_hold', 'start_daterange', 'end_daterange', 'vertical_id', 'bulk_update_enabled', function () {
      // NOTE: clear selection in case any filter changed or :bulk_update_enabled switched
      this.set('selectedMissionsNumbers', []);
    }),
    /* eslint-enable ember/no-observers */

    loadedMissionsCount: Ember.computed('model', 'model.missions.length', function () {
      return this.get('model.missions.length');
    }),
    showTotalPrice: Ember.computed('model.currentAdmin.canSeeBulkUpdater', function () {
      return this.get('model.currentAdmin.canSeeBulkUpdater');
    }),
    selectedOnlyOneStatus: Ember.computed('status', function () {
      return !!this.get('status') && this.get('status').split(',').length == 1;
    }),
    // Bulk update mission checkboxes dependencies -----------------------------------------------------------------------
    allAvailableBulkUpdateMissionNumbers: Ember.computed('model.missions.meta.available_missions_numbers', function () {
      return this.get('model.missions.meta.available_missions_numbers') || [];
    }),
    allAvailableBulkUpdateMissionSelected: Ember.computed('model.missions.meta.available_missions_numbers', 'selectedMissionsNumbers.length', function () {
      const availableNumbers = this.get('allAvailableBulkUpdateMissionNumbers');
      const selectesNumbers = this.get('selectedMissionsNumbers');
      return availableNumbers.sort().toString() === selectesNumbers.sort().toString();
    }),
    availableMissions: Ember.computed('model.missions.length', function () {
      return this.get('model.missions').filter(mission => !mission.get('isOnHold'));
    }),
    needsToShowWarningBeforeBulkAdditionToCapturGroup: Ember.computed('availableMissionsWithAssignedPilotsCount', 'availableMissionsAssignedToCaptureGroupCount', 'availableMissionsInProgressCount', function () {
      return this.get('availableMissionsWithAssignedPilotsCount') > 0 || this.get('availableMissionsAssignedToCaptureGroupCount') > 0 || this.get('availableMissionsInProgressCount') > 0;
    }),
    selectedAvailableMissions: Ember.computed('selectedMissionsNumbers', 'model.missions.length', function () {
      return this.get('model.missions').filter(mission => this.get('selectedMissionsNumbers').includes(mission.get('id')));
    }),
    availableMissionsWithAssignedPilotsCount: Ember.computed('selectedMissionsNumbers', 'model.missions.length', function () {
      return this.get('selectedAvailableMissions').filter(mission => mission.get('pilot_id')).length;
    }),
    availableMissionsAssignedToCaptureGroupCount: Ember.computed('selectedMissionsNumbers', 'model.missions.length', function () {
      return this.get('selectedAvailableMissions').filter(mission => mission.get('capture_group_id')).length;
    }),
    availableMissionsInProgressCount: Ember.computed('selectedMissionsNumbers', 'model.missions.length', function () {
      return this.get('selectedAvailableMissions').filter(mission => mission.get('isInProgress')).length;
    }),
    allAvailableMissionsAreSelected: Ember.computed('selectedMissionsNumbers.length', 'availableMissions.length', function () {
      return this.get('selectedMissionsNumbers.length') > 0 && this.get('selectedMissionsNumbers.length') >= this.get('availableMissions.length');
    }),
    isIndeterminated: Ember.computed('selectedMissionsNumbers.length', 'allAvailableMissionsAreSelected', function () {
      return this.get('selectedMissionsNumbers.length') > 0 && !this.get('allAvailableMissionsAreSelected');
    }),
    testEnvironment: Ember.computed(function () {
      return _environment.default.environment === 'test';
    }),
    // Update button attrs -----------------------------------------------------------------------------------------------
    bulkUpdateAvailable: Ember.computed('model.currentAdmin.canSeeBulkUpdater', function () {
      return this.get('model.currentAdmin.canSeeBulkUpdater');
    }),
    bulkUpdateActionDisabled: Ember.computed('bulkUpdateAvailable', 'selectedMissionsNumbers.length', 'status', function () {
      return !this.get('bulkUpdateAvailable') || !this.get('selectedMissionsNumbers.length');
    }),
    bulkUpdateActionTooltipText: Ember.computed('bulkUpdateActionDisabled', 'bulkUpdateAvailable', 'selectedMissionsNumbers.length', 'status', function () {
      if (!this.get('bulkUpdateActionDisabled')) return;
      if (!this.get('bulkUpdateAvailable')) {
        return 'Action is not available for your role';
      } else if (!this.get('selectedMissionsNumbers.length')) {
        return 'No selected inspections';
      } else if (!this.get('status')) {
        return 'Status not selected (at filter)';
      } else {
        return;
      }
    }),
    // Bulk update modal dependencies ------------------------------------------------------------------------------------
    showBulkStatusUpdateErrorsModal(failedMissions, bulkUpdateMissionsCount) {
      this.set('bulkStatusUpdateErrorsModalVisible', true);
      this.set('bulkStatusUpdateErrors', failedMissions);
      this.set('bulkUpdateMissionsCount', bulkUpdateMissionsCount);
    },
    closeBulkStatusUpdateErrorsModal() {
      this.set('bulkStatusUpdateErrorsModalVisible', false);
      this.set('bulkStatusUpdateErrors', null);
      this.set('bulkUpdateMissionsCount', null);
    },
    // Multi-line/attribute search dependencies
    qOneline: Ember.computed('qCompacted', function () {
      return this.get('qCompacted').split(this.get('NEW_LINE_CHAR')).join(', ');
    }),
    qLinesCount: Ember.computed('qCompacted', function () {
      if (this.get('qCompacted')) {
        return this.get('qCompacted').split(this.get('NEW_LINE_CHAR')).length;
      } else {
        return 0;
      }
    }),
    qCompacted: Ember.computed('qInput', function () {
      return this.get('qInput').trim().replaceAll(/\n+/g, "\n");
    }),
    queryReachedLengthLimit: Ember.computed('qInput', function () {
      return this.get('qInput').length >= this.get('QUERY_LENGTH_LIMIT');
    }),
    notMatchedQueryLines: Ember.computed('model.missions.meta.matched_queries', function () {
      const matchedQueries = this.model.missions.meta.matched_queries;
      if (!this.qCompacted || !matchedQueries) return [];
      return this.qCompacted.split(this.get('NEW_LINE_CHAR')).reduce((arr, qLine) => {
        if (!matchedQueries.includes(qLine)) arr.push(qLine);
        return arr;
      }, []);
    }),
    submitSearch() {
      this.set('q', this.get('qInput'));
    },
    recalculateTextareaHeight() {
      const textarea = document.querySelector('#searchTextarea');
      const valueNumberOfLineBreaks = (textarea.value.match(/\n/g) || []).length + 2; // +2 for more space
      const borderTop = parseInt(getComputedStyle(textarea).borderTopWidth, 10);
      const borderBottom = parseInt(getComputedStyle(textarea).borderBottomWidth, 10);
      const paddingTop = parseInt(getComputedStyle(textarea).paddingTop, 10);
      const paddingBottom = parseInt(getComputedStyle(textarea).paddingBottom, 10);
      const lineHeight = parseInt(getComputedStyle(textarea).lineHeight, 10);
      const minHeight = parseInt(getComputedStyle(textarea).minHeight, 10);
      const textHeight = valueNumberOfLineBreaks * lineHeight;
      if (textHeight > minHeight) {
        textarea.style.height = textHeight + borderTop + borderBottom + paddingTop + paddingBottom + 'px';
      } else {
        textarea.style.height = minHeight + 'px';
      }
    },
    // Bulk actions functions
    addMissionsToExistingCaptureGroup(captureGroup) {
      const missionsNumbers = this.get('selectedMissionsNumbers');
      const captureGroupNumber = captureGroup.get('id');
      const data = {
        mission_ids: missionsNumbers,
        capture_group_number: captureGroupNumber
      };
      const responseHandler = () => {
        this.set('selectedMissionsNumbers', []);
        this.set('bulkAddToExistingCaptureGroupModalVisible', false);
        this.flashMessages.success(`<div class="ver-25">
          <div class="body-2-semibold">${missionsNumbers.length} inspections were added to Capture group</div>
          <div class="caption-1-regular">Capture group: ${captureGroupNumber}</div>
        </div>`, {
          timeout: 5000
        });
        this.set('bulkAddToCGCallback', null);
        this.get('model.missions').filter(mission => missionsNumbers.includes(mission.get('id'))).setEach('capture_group_id', captureGroupNumber);
      };
      return this.get('store').adapterFor('mission').bulkAddToExistingCaptureGroup(data).then(() => responseHandler()).catch(response => {
        if (response.status == 422 && response.responseJSON?.errors) {
          response.responseJSON.errors.forEach(error => {
            this.flashMessages.error(error.detail);
          });
        } else {
          this.flashMessages.error('Failed to add missions to existing Capture Group');
        }
      });
    },
    addMissionsToNewCaptureGroup(captureGroupName) {
      const missionsNumbers = this.get('selectedMissionsNumbers');
      const data = {
        mission_ids: missionsNumbers,
        capture_group_name: captureGroupName
      };
      const responseHandler = captureGroupNumber => {
        this.set('selectedMissionsNumbers', []);
        this.set('bulkAddToNewCaptureGroupModalVisible', false);
        this.flashMessages.success(`<div class="ver-25">
          <div class="body-2-semibold">${missionsNumbers.length} inspections were added to Capture group</div>
          <div class="caption-1-regular">Capture group: ${captureGroupName}</div>
        </div>`, {
          timeout: 5000
        });
        this.set('bulkAddToCGCallback', null);
        this.get('model.missions').filter(mission => missionsNumbers.includes(mission.get('id'))).setEach('capture_group_id', captureGroupNumber);
      };
      return this.get('store').adapterFor('mission').bulkAddToNewCaptureGroup(data).then(response => responseHandler(response.capture_group_number)).catch(response => {
        if (response.status == 422 && response.responseJSON?.errors) {
          response.responseJSON.errors.forEach(error => {
            this.flashMessages.error(error.detail);
          });
        } else {
          this.flashMessages.error('Failed to add missions to new Capture Group');
        }
      });
    },
    actions: {
      checkMission(mission, event) {
        const selectedMissionNumbers = this.get('selectedMissionsNumbers');
        const missionNumber = mission.get('id');
        const newSelectedMissionNumbers = event.target.checked ? [...selectedMissionNumbers, missionNumber] : selectedMissionNumbers.filter(number => number !== missionNumber);
        this.set('selectedMissionsNumbers', Array.from(new Set(newSelectedMissionNumbers)));
      },
      checkAllLoadedMissions() {
        if (this.get('allAvailableMissionsAreSelected')) return this.set('selectedMissionsNumbers', []);
        const selectedMissionNumbers = this.get('selectedMissionsNumbers');
        const availableMissionsNumbers = this.get('availableMissions').getEach('id');
        this.set('selectedMissionsNumbers', Array.from(new Set([...selectedMissionNumbers, ...availableMissionsNumbers])));
      },
      checkAllMissions() {
        const selectedMissionNumbers = this.get('selectedMissionsNumbers');
        const allAvailableBulkUpdateMissionNumbers = this.get('allAvailableBulkUpdateMissionNumbers');
        this.set('selectedMissionsNumbers', Array.from(new Set([...selectedMissionNumbers, ...allAvailableBulkUpdateMissionNumbers])));
      },
      switchBulkUpdateMode() {
        this.toggleProperty('bulk_update_enabled');
      },
      changeCountryCodes(countryCodes) {
        return this.set('country_codes[]', countryCodes);
      },
      download() {
        let query = {
          'mission_types': 'client'
        };
        this.get("queryParams").forEach(property => {
          query[property] = this.get(property);
        });
        this.set('busy', true);
        this.set('downloaded', "0 bytes");
        return this.get('store').adapterFor('mission').download(query, {
          onProgress: progress => this.set('downloaded', Math.round(progress.loaded / 1024) + "KB")
        }).catch(xhr => {
          if (xhr.statusText === "Forbidden") {
            alert("You do not have permission to export to CSV");
          } else {
            alert(arguments[0].responseText);
          }
        }).always(() => this.set('busy', false));
      },
      toggleShowMap() {
        this.toggleProperty('showMap');
      },
      showBulkUpdateModal() {
        if (!this.get('selectedOnlyOneStatus')) return;
        this.set('bulkStatusUpdateModalVisible', true);
        this.set('showActionsDropdown', false);
      },
      closeBulkUpdateModal() {
        this.set('bulkStatusUpdateModalVisible', false);
      },
      showBulkAddToExistingCaptureGroupModal() {
        this.set('bulkAddToExistingCaptureGroupModalVisible', true);
        this.set('showActionsDropdown', false);
      },
      closeBulkAddToExistingCaptureGroupModal() {
        this.set('bulkAddToExistingCaptureGroupModalVisible', false);
      },
      showBulkAddToNewCaptureGroupModal() {
        this.set('bulkAddToNewCaptureGroupModalVisible', true);
        this.set('showActionsDropdown', false);
      },
      closeBulkAddToNewCaptureGroupModal() {
        this.set('bulkAddToNewCaptureGroupModalVisible', false);
      },
      closeBulkAddToCaptureGroupWarningModal() {
        this.set('bulkAddToCaptureGroupWarningModalVisible', false);
      },
      showBulkStatusUpdateErrorsModal(failedMissions) {
        this.showBulkStatusUpdateErrorsModal(failedMissions);
      },
      closeBulkStatusUpdateErrorsModal() {
        this.closeBulkStatusUpdateErrorsModal();
      },
      setSort(sort) {
        this.set('sortProperties', [sort]);
        const arrSort = sort.split(':');
        this.set('sort_attribute', arrSort[0]);
        this.set('sort_order', arrSort[1]);
      },
      openMultiLineSearch() {
        this.set('multiLineSearchOpened', true);
        Ember.run.schedule("afterRender", this, () => {
          this.recalculateTextareaHeight();
          document.querySelector('#searchTextarea').focus();
        });
      },
      closeMultiLineSearch() {
        this.set('multiLineSearchOpened', false);
      },
      textareaKeyUp() {
        this.recalculateTextareaHeight();
        Ember.run.debounce(this, this.submitSearch, 1000);
      },
      clearQuery() {
        this.set('q', '');
        this.set('qInput', '');
      },
      toggleActionsDropdown() {
        this.toggleProperty('showActionsDropdown');
      },
      toggleFilter() {
        this.set('hideFilter', false);
      },
      updateMissionsStatus(formObject) {
        const missionsNumbers = this.get('selectedMissionsNumbers');
        const newStatus = formObject.get('status');
        const data = {
          mission_ids: missionsNumbers,
          new_status: newStatus
        };
        if (newStatus == 'canceled') {
          data['cancellation_params'] = {
            cancellation_reason_id: formObject.get('cancellation_reason_id'),
            notes: formObject.get('notes')
          };
        }
        const responseHandler = (failedMissions = []) => {
          const failedMissionsNumbers = failedMissions.map(failedMission => failedMission.number);
          if (failedMissions.length) this.showBulkStatusUpdateErrorsModal(failedMissions, missionsNumbers.length);
          Ember.run(() => {
            this.set('bulkStatusUpdateModalVisible', false);
            this.set('selectedMissionsNumbers', []);
            this.get('model.missions').filter(mission => {
              return !failedMissionsNumbers.includes(mission.get('id')) && missionsNumbers.includes(mission.get('id'));
            }).setEach('status', newStatus);
            this.flashMessages.success(`Status updated for ${missionsNumbers.length - failedMissionsNumbers.length} inspections`);
          });
        };
        this.set('bulkUpdating', true);
        return this.get('store').adapterFor('mission').bulkStatusUpdate(data).then(() => responseHandler()).catch(response => {
          if (response.status == 422 && response.responseJSON?.failed_missions) {
            responseHandler(response.responseJSON.failed_missions);
          } else {
            alert('Error while missions update');
          }
        }).always(() => this.set('bulkUpdating', false));
      },
      confirmMissionsAdditionToCaptureGroup() {
        this.set('bulkAddToCaptureGroupWarningModalVisible', false);
        this.get('bulkAddToCGCallback')();
      },
      addMissionsToExistingCaptureGroup(captureGroup) {
        if (this.get('needsToShowWarningBeforeBulkAdditionToCapturGroup')) {
          this.set('bulkAddToCaptureGroupWarningModalVisible', true);
          this.set('bulkAddToCGCallback', () => this.addMissionsToExistingCaptureGroup(captureGroup));
          return;
        }
        this.addMissionsToExistingCaptureGroup(captureGroup);
      },
      addMissionsToNewCaptureGroup(captureGroupName) {
        if (this.get('needsToShowWarningBeforeBulkAdditionToCapturGroup')) {
          this.set('bulkAddToCaptureGroupWarningModalVisible', true);
          this.set('bulkAddToCGCallback', () => this.addMissionsToNewCaptureGroup(captureGroupName));
          return;
        }
        this.addMissionsToNewCaptureGroup(captureGroupName);
      }
    }
  });
});