define("admin/components/site-inspections/bulk-update-errors-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    bulkUpdateErrors: null,
    copyButtonText: 'Copy Work Order IDs',
    bulkUpdateErrorsForNotUpdatedInspections: Ember.computed('bulkUpdateErrors', function () {
      if (!this.get('bulkUpdateErrors.length')) return [];
      return this.get('bulkUpdateErrors').filter(error => !error['updated']);
    }),
    bulkUpdateErrorsForUpdatedInspections: Ember.computed('bulkUpdateErrors', function () {
      if (!this.get('bulkUpdateErrors.length')) return [];
      return this.get('bulkUpdateErrors').filter(error => error['updated']);
    }),
    actions: {
      copyFailedInspectionsNumbers() {
        navigator.clipboard.writeText(this.get('bulkUpdateErrors').map(error => error.number).join('\n'));
        this.set('copyButtonText', 'Copied work order IDs!');
        setTimeout(() => {
          this.set('copyButtonText', 'Copy work order IDs');
        }, '2000');
      },
      closeModal() {
        this.closeBulkUpdateErrorsModal();
      }
    }
  });
});